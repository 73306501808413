
import { defineComponent, nextTick, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ApiService from "@/core/services/ApiService";
import {
  saveOrganization,
  saveRehab,
  saveUser,
  saveExpiresAt,
} from "@/core/services/JwtService";
import { getManager } from "@/store/modules/AuthModule";
import { Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "sign-in",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const userManager = getManager();
    onMounted(async () => {
      if (userManager) {
        await check();
        //window.location.search = window.location.host;
        router.push({ path: "homePage" });
      }
    });

    async function check() {
      //oidc cliernt parser problem with Vue router hash history
      let url = new URL(window.location.href, "http://127.0.0.1");
      url.search = decodeURIComponent(window.location.hash).replace(
        "#/callback?",
        ""
      );
      var user = await userManager.signinRedirectCallback(
        decodeURIComponent(url.href)
      );
      if (user) {
        store.commit(Mutations.SET_AUTH, user);
        saveUser(user);
        saveExpiresAt(user.expires_at);
        //For future tests
        //saveExpiresAt(new Date(new Date().getTime() + 2*60000).getTime());
        saveRehab("");
        ApiService.setHeader();
        ApiService.setUserHeader(JSON.stringify(user.profile));
      }
    }


    function clean() {
      store.commit(Mutations.PURGE_AUTH);
      saveUser(null);
      ApiService.setUserHeader(null);
      ApiService.setHeader();
    }

    async function testAuth() {
      await ApiService.get("api/DemoAuth/TestAuth");
    }

    //Form submit function

    return { check, testAuth, clean };
  },
});
